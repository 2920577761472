<template>
  <div class="card">
    <template v-if="remarkList.length>0">
      <div class="remarks-container">
        <div
          v-for="(item, index) in remarkList"
          :key="index"
          class="remark-box">
          <div class="remark-header">
            <span>{{ item.author }}</span>
            <span>{{ item.created_at }}</span>
          </div>
          <p>{{ item.remark }}</p>
        </div>
      </div>
    </template>
    <div
      class="remark-add"
      :class="{'has-border':remarkList.length>0}">
      <el-form
        ref="form"
        :model="formData"
        :rules="rules"
      >
        <el-form-item prop="remark">
          <v-input
            v-model="formData.remark"
            class="mg-b-15"
            @keyEnter="handlerAddRemark" />
        </el-form-item>
      </el-form>
      <div class="card-button">
        <v-button
          size="small"
          class="button-blue button-theme-padding-13"
          @click="handlerAddRemark">Add Remark</v-button>
      </div>
    </div>
  </div>
</template>

<script>
import {getInternalMarkList,addInternalMark} from'@/api/order'
import MixinRules from '@/mixins/rules'
import MixinOptions from '@/mixins/options'
export default {
  name: 'internetMark',
  mixins: [MixinRules,MixinOptions],
  props:{
  },
  data(){
    return{
      remarkList:[],
      formData:{
        remark:''
      }
    }
  },
  computed:{
    orderNo(){
      let {order_no,isDraft} = this.$route.query||{}
      return order_no
    }
  },
  mounted() {
    this.network().getInternalMarkList()
  },
  methods:{
    handlerAddRemark(){
      this.$refs.form.validate((valid,rules) => {
        if (valid) {
          this.network().addInternalMark()
        }else {
          this.scrollToValidDom(rules)
        }
      })
    },
    network(){
      return{
        getInternalMarkList:async (params)=>{
          params = {
            ...params,
            order_no:this.orderNo
          }
          if(!params.order_no) return
          let {data} = await getInternalMarkList(params)
          this.remarkList = data
        },
        addInternalMark:async (params)=>{
          params = {
            ...this.formData,
            order_no:this.orderNo
          }
          if(!params.order_no) return
          let {data} = await addInternalMark(params)
          this.formData.remark = ''
          this.network().getInternalMarkList()
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.remarks-container{
  padding: 20px;
  &::-webkit-scrollbar {
    width: 6px; // 横向滚动条
    height: 12px; // 纵向滚动条 必写
    display: block;
    background: #CBD5E0;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #718096;
    border-radius: 3px;
    cursor: pointer;
  }
}
.remark-box{
  margin-bottom: 16px;

  &:last-child{
    margin-bottom: 0;
  }
  .remark-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    span{
      color: #2D3748;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      line-height: 150%;
      &:nth-child(1){
        font-weight: 500;
      }
    }
  }
  p{
    color: #718096;
    /* Medium/Small */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
}
.remark-add{
  &.has-border{
    border-top: 1px solid  #DCDFE6;
  }
  .el-form-item{
    margin-bottom: 15px;
  }
  padding: 20px;
}
</style>
