export default {
  computed:{
    remarkInit(){
      return '如發生意外，必須於七日內向保險公司申報。\n' +
          '如有任何問題請致電本公司查詢。\n' +
          'In case of accident, it is a must to report to insurance company within 7 days.\n' +
          'If you have any questions, please feel free to call us.'
    },
    isRenew(){
      let {policy_type,isCreateRenew=false} = this.quoteInformationForm||{}
      let {isDraft} = this.$route.query
      return policy_type==201
    },
  }
}
