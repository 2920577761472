<template>
  <section class="dirver-form">
    <div class="flex">
      <v-title :title="titles"></v-title>
      <div>
        <v-button
          class="button-theme"
          @click="handlerAddDriver">
          Add {{showMotor?'Driver':'Traveler'}}
        </v-button>
      </div>
    </div>
    <el-row align="middle">
      <v-row-col>
        <!--        <v-row-col-->
        <!--          same>-->
        <!--          <div class="form-item ">-->
        <!--            <span>Are all the driver(s) has not been involved in a motor accident in the past 3 years?</span>-->
        <!--            <div class="car-type-radio">-->
        <!--              <el-form-item prop="relationship_id">-->
        <!--                <v-radio-group-->
        <!--                  v-model="formData.driver[0].has_accident"-->
        <!--                  :border="true"-->
        <!--                  :list="accidentsOption"-->
        <!--                />-->
        <!--              </el-form-item>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </v-row-col>-->
        <!--        <v-row-col-->
        <!--          same>-->
        <!--          <div-->
        <!--            v-if="formData.driver[0].has_accident==2"-->
        <!--            class="form-item ">-->
        <!--            <span>Number of accident in last 3 years</span>-->
        <!--            <el-form-item-->
        <!--              prop="relationship_id">-->
        <!--              <v-select-->
        <!--                v-model="formData.driver[0].accidents"-->
        <!--                :list="globalOptions.accidents"-->
        <!--              />-->
        <!--            </el-form-item>-->
        <!--          </div>-->
        <!--        </v-row-col>-->
        <!--        <v-row-col same>-->
        <!--          <div class="form-item ">-->
        <!--            <span>Are all the driver(s) has not been deducted driving offence points in the past 3 years?</span>-->
        <!--            <div class="car-type-radio">-->
        <!--              <el-form-item prop="relationship_id">-->
        <!--                <v-radio-group-->
        <!--                  v-model="formData.driver[0].is_deducted_point"-->
        <!--                  :list="deductedOption"-->
        <!--                  border-->
        <!--                />-->
        <!--              </el-form-item>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </v-row-col>-->

        <!--        <v-row-col same>-->
        <!--          <div-->
        <!--            v-if="formData.driver[0].is_deducted_point==2"-->
        <!--            class="form-item ">-->
        <!--            <template >-->
        <!--              <span>Number of driving offence point in last 3 years</span>-->
        <!--              <el-form-item-->
        <!--                prop="relationship_id">-->
        <!--                <v-select-->
        <!--                  v-model="formData.driver[0].deducted_points"-->
        <!--                  :list="globalOptions.deducted_points"-->
        <!--                />-->
        <!--              </el-form-item>-->
        <!--            </template>-->
        <!--          </div>-->
        <!--        </v-row-col>-->
        <v-row-col
          v-if="carType!==1004&&showMotor&&clientType==1"
          same>
          <v-row-col
            :same="true"
          >
            <div class="form-item">
              <span>Are you the driver of this car?</span>
              <div class="car-type-radio">
                <el-form-item prop="policy_holder_is_driver">
                  <v-radio-group
                    v-model.number="formData.policy_holder_is_driver"
                    :border="true"
                    :list="yesNo2Options"
                    @change="handlerPolicyDriverInfo"/>
                </el-form-item>
              </div>
            </div>
          </v-row-col>
        </v-row-col>
      </v-row-col>
    </el-row>

    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <el-row
        v-for="(item,index) in formData.driver"
        :key="index"
        class="row-bg"
        :gutter="20"
        justify="space-around"
      >
        <div class="flex">
          <span>{{showMotor?'Driver':'Traveler'}}{{ index + 1 }}</span>
          <span
            class="hand"
            @click="handlerDeleteDriver(index)">Delete</span>
        </div>
        <div class="flex">
          <v-row-col
            same
            class="pd-l-0"
            :same-number="4">
            <div class="form-item">
              <span>Title</span>
              <div >
                <el-form-item
                  :ref="'driver.' + index + '.title'"
                  :rules="rules.select"
                  :prop="'driver.' + index + '.title'">
                  <v-select
                    v-model="item.title"
                    border
                    :list="titleOption"
                    :disabled="isDisable&&index==0"
                  />
                </el-form-item>
              </div>
            </div>
          </v-row-col>
          <v-row-col
            same
            :same-number="10">
            <div class="form-item">
              <span>Last Name</span>
              <el-form-item
                :ref="'driver.' + index + '.last_name'"
                :prop="'driver.' + index + '.last_name'"
                :rules="rules.last_name">
                <v-input
                  v-model="item.last_name"
                  is-upper
                  :disabled="isDisable&&index==0"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            same
            class="pd-r-0"
            :same-number="10">
            <div class="form-item">
              <span>First Name</span>
              <el-form-item
                :ref="'driver.' + index + '.first_name'"
                :show-message="false"
                :prop="'driver.' + index + '.first_name'"
                :rules="rules.first_name"
              >
                <v-input
                  v-model="item.first_name"
                  is-upper
                  :disabled="isDisable&&index==0"
                />
              </el-form-item>
            </div>
          </v-row-col>
        </div>
        <v-row-col>
          <div class="form-item">
            <!--                  <span>HKID/Passport Number</span>-->
            <el-form-item
              :ref="'driver.' + index + '.id_type'"
              :prop="'driver.' + index + '.id_type'"
              class="form-none-margin-bottom">
              <v-radio-group
                v-model="item.id_type"
                :disabled="isDisable&&index==0"
                :list="personalIdTypOptions"/>
            </el-form-item>
            <el-form-item
              v-if="item.id_type!=2"
              :ref="'driver.' + index + '.id_no'"
              :prop="'driver.' + index + '.id_no'"
              :rules="rules.id_no"
            >
              <v-input
                v-model="item.id_no"
                class="mg-t-5"
                :disabled="isDisable&&index==0"
                :isUpper="true"
              />
            </el-form-item>
            <el-form-item
              v-else
              :ref="'driver.' + index + '.pp_no'"
              :prop="'driver.' + index + '.pp_no'"
              :rules="rules.input"
            >
              <v-input
                v-model="item.pp_no"
                is-upper
                :disabled="isDisable&&index==0"
                class="mg-t-5"/>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col>
          <div class="form-item ">
            <span>Date of Birth</span>
            <el-form-item
              :ref="'driver.' + index + '.birth'"
              :rules="rules.select"
              :prop="'driver.' + index + '.birth'">
              <v-date-picker
                v-model="item.birth"
                :hasDeault="true"
                :disabled="isDisable&&index==0"
              ></v-date-picker>
            </el-form-item>
          </div>
        </v-row-col>

        <v-row-col>
          <div class="form-item ">
            <span>Relationship with Proposer</span>
            <el-form-item
              :ref="'driver.' + index + '.relationship_id'"
              :prop="'driver.' + index + '.relationship_id'"
              :rules="rules.select">
              <v-select
                v-model="item.relationship_id"
                :disabled="isDisable&&index==0"
                :list="isDisable&&index!=0||isCompany?relationshipList:relationshipTypeList"
              />
            </el-form-item>
          </div>
        </v-row-col>

        <v-row-col v-if="showMotor">
          <div
            v-if="isMotorcycle"
            class="form-item">
            <span >Type of Motorcycle Driving licence</span>
            <el-form-item
              :ref="'driver.' + index + '.driving_licence_id'"
              :prop="'driver.' + index + '.driving_licence_id'"
              :rules="rules.select" >
              <v-select
                v-model="item.driving_licence_id"
                :list="globalOptions.driving_licence"
              ></v-select>
            </el-form-item>
          </div>
          <div
            v-else
            class="form-item">
            <span>Driving Experience</span>
            <el-form-item
              :ref="'driver.' + index + '.driver_experience_id'"
              :prop="'driver.' + index + '.driver_experience_id'"
              :rules="rules.select" >
              <v-select
                v-model="item.driver_experience_id"
                :list="globalOptions.driving_experience"
              ></v-select>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col v-if="isMotorcycle&&item.driving_licence_id==3">
          <div
            class="form-item">
            <span >Year of driving Experience</span>
            <el-form-item
              :ref="'driver.' + index + '.driver_experience_id'"
              :prop="'driver.' + index + '.driver_experience_id'"
              :rules="rules.select" >
              <v-select
                v-model="item.driver_experience_id"
                :list="dirverExpList(1003)"
              ></v-select>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col v-if="showMotor">
          <div class="form-item">
            <span>Occupation</span>
            <el-form-item
              :ref="'driver.' + index + '.occ_id'"
              :rules="rules.select"
              :prop="'driver.' + index + '.occ_id'"
            >
              <v-select
                v-model="item.occ_id"
                :list="globalOptions.occ"
                :disabled="isDisable&&index==0"
              ></v-select>
            </el-form-item>
            <el-form-item
              v-if="item.occ_id==70"
              :ref="'driver.' + index + '.occ'"
              :rules="rules.input"
              :prop="'driver.' + index + '.occ'"
            >
              <v-input
                v-model="item.occ"
                :placeholder="'Please enter'"
                :disabled="isDisable&&index==0"
              ></v-input>
            </el-form-item>
          </div>
        </v-row-col>
        <div class="form-half">
          <v-row-col same>
            <div
              class="form-item ">
              <template >
                <span>Number of driving offence point in last 3 years (Excludes traffic accidents)</span>
                <el-form-item
                  :ref="'driver.' + index + '.deducted_points'"
                  :prop="'driver.' + index + '.deducted_points'"
                  :rules="rules.select">
                  <v-select
                    v-model="item.deducted_points"
                    :list="globalOptions.deducted_points"
                  />
                </el-form-item>
              </template>
            </div>
          </v-row-col>
          <v-row-col
            v-if="item.deducted_points!=0"
            same
          >
            <div
              class="form-item ">
              <template >
                <span>Reason</span>
                <el-form-item
                  :ref="'driver.' + index + '.deducted_point_reason_id'"
                  :prop="'driver.' + index + '.deducted_point_reason_id'"
                  :rules="rules.select">
                  <v-checkbox-group
                    v-model="item.deducted_point_reason_id"
                    :list="globalOptions.deducted_point_reason"
                    block
                  />
                </el-form-item>
                <el-form-item
                  v-if="item.deducted_point_reason_id&&item.deducted_point_reason_id.includes(4)"
                  :ref="`driver.${index}.deducted_point_reason`"
                  :prop="`driver.${index}.deducted_point_reason`"
                  :show-message="false"
                  :rules="rules.input"
                >
                  <v-input
                    v-model="item.deducted_point_reason"
                    type="textarea"
                    :rows="4"
                  ></v-input>
                </el-form-item>
              </template>
            </div>
          </v-row-col>
        </div>
        <div class="form-half">

          <v-row-col same>
            <div
              class="form-item ">
              <template >
                <span>Have any been involved in any car accident or motor claim in the past 3 years?</span>
                <!--              <el-form-item-->
                <!--                prop="deducted_points">-->
                <!--                <v-select-->
                <!--                  v-model="item.has_accident"-->
                <!--                  :list="globalOptions.has_accident"-->
                <!--                />-->
                <!--              </el-form-item>-->
                <div class="car-type-radio">
                  <el-form-item
                    :ref="'driver.' + index + '.has_accident'"
                    :prop="'driver.' + index + '.has_accident'"
                    :rules="rules.select">
                    <v-radio-group
                      v-model.number="item.has_accident"
                      :border="true"
                      :list="yesNo3Options"
                    />
                  </el-form-item>
                </div>
              </template>
            </div>
          </v-row-col>
          <v-row-col
            v-if="item.has_accident==2"
            same>
            <div
              class="form-item ">
              <el-form-item
                :ref="'driver.' + index + '.accident_reason_id'"
                :prop="'driver.' + index + '.accident_reason_id'"
                :rules="rules.select">
                <v-checkbox-group
                  v-model="item.accident_reason_id"
                  :list="globalOptions.accident_reason"
                  block
                />
              </el-form-item>
              <template v-if="item.accident_reason_id&&item.accident_reason_id.includes(4)">
                <!--              <span>Reason （within 50 characters）</span>-->
                <el-form-item
                  :ref="`driver.${index}.accident_reason`"
                  :prop="`driver.${index}.accident_reason`"
                  :rules="rules.input"
                >
                  <v-input
                    v-model="item.accident_reason"
                    type="textarea"
                    :rows="4"
                  />
                </el-form-item>
              </template>
            </div>
          </v-row-col>
          <v-row-col same>
            <div
              class="form-item ">
              <template >
                <span>Have any been declined motor insurance, had a motor insurance policy cancelled?</span>
                <div class="car-type-radio">
                  <el-form-item
                    :ref="`driver.${index}.is_declined`"
                    :prop="`driver.${index}.is_declined`"
                    :rules="rules.questionCheck">
                    <v-radio-group
                      v-model.number="item.is_declined"
                      :border="true"
                      :list="yesNo2Options"
                    />
                  </el-form-item>
                </div>
              </template>
            </div>
          </v-row-col>
          <v-row-col
            v-if="item.is_declined==1"
            same>
            <div
              class="form-item ">
              <template >
                <!--              <span>Reason （within 50 characters）</span>-->
                <el-form-item
                  :ref="`driver.${index}.declined_reason`"
                  :prop="`driver.${index}.declined_reason`"
                  :rules="rules.input">
                  <v-input
                    v-model="item.declined_reason"
                    type="textarea"
                    :rows="4"
                  />
                </el-form-item>
              </template>
            </div>
          </v-row-col>
          <v-row-col same>
            <div
              class="form-item ">
              <template >
                <span>Have any suffered from any physical or mental infirmity that may affect his/her ability to drive?</span>
                <!--              <el-form-item-->
                <!--                prop="deducted_points">-->
                <!--                <v-select-->
                <!--                  v-model="item.is_no_driving_ability"-->
                <!--                  :list="globalOptions.has_accident"-->
                <!--                />-->
                <!--              </el-form-item>-->
                <div class="car-type-radio">
                  <el-form-item
                    :ref="`driver.${index}.is_no_driving_ability`"
                    :prop="`driver.${index}.is_no_driving_ability`"
                    :rules="rules.questionCheck">
                    <v-radio-group
                      v-model.number="item.is_no_driving_ability"
                      :border="true"
                      :list="yesNo2Options"
                    />
                  </el-form-item>
                </div>
              </template>
            </div>
          </v-row-col>
          <v-row-col
            v-if="item.is_no_driving_ability==1"
            same>
            <div
              class="form-item ">
              <template >
                <!--              <span>Reason （within 50 characters）</span>-->
                <el-form-item
                  :ref="`driver.${index}.no_driving_ability_reason`"
                  :prop="`driver.${index}.no_driving_ability_reason`"
                  :rules="rules.input">
                  <v-input
                    v-model="item.no_driving_ability_reason"
                    type="textarea"
                    :rows="4"
                  />
                </el-form-item>
              </template>
            </div>
          </v-row-col>
          <v-row-col same>
            <div
              class="form-item ">
              <template >
                <span>Have been prosecuted by police, Dangerous Driving or driving licence suspended or disqualified by court ?</span>
                <!--              <el-form-item-->
                <!--                prop="is_dangerous_driving">-->
                <!--                <v-select-->
                <!--                  v-model="item.is_dangerous_driving"-->
                <!--                  :list="globalOptions.is_dangerous_driving"-->
                <!--                />-->
                <!--              </el-form-item>-->
                <div class="car-type-radio">
                  <el-form-item
                    :ref="`driver.${index}.is_dangerous_driving`"
                    :prop="`driver.${index}.is_dangerous_driving`"
                    :rules="rules.questionCheck">
                    <v-radio-group
                      v-model.number="item.is_dangerous_driving"
                      :border="true"
                      :list="yesNo2Options"
                    />
                  </el-form-item>
                </div>
              </template>
            </div>
          </v-row-col>
          <v-row-col
            v-if="item.is_dangerous_driving==1"
            same>
            <div
              class="form-item ">
              <template >
                <!--              <span>Reason （within 50 characters）</span>-->
                <el-form-item
                  :ref="`driver.${index}.dangerous_driving_reason`"
                  :prop="`driver.${index}.dangerous_driving_reason`"
                  :rules="rules.input">
                  <v-input
                    v-model="item.dangerous_driving_reason"
                    type="textarea"
                    :rows="4"
                  />
                </el-form-item>
              </template>
            </div>
          </v-row-col>
        </div>
      </el-row>
    </el-form>
  </section>
</template>

<script>
import options from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import IDCheck from '@/utils/idcheck'
import {mapState} from 'vuex'

export default {
  name: 'driverDetailForm',
  mixins: [options,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    carType: {
      type: Number,
      default: 0
    },
    policyOneDriver:{
      type: Boolean,
      default: false
    },
    clientType:{
      type: Number,
      default: 1
    }
  },
  data() {
    const checkIdNo = (rule, value, callback) => {
      const index = rule.field.split('.')[1]
      const { id_no,id_type } = this.formData.driver[index]||{}
      if( id_no&&!IDCheck(id_no)) {
        callback(new Error('請輸入正確的身份證號碼'))
      }
      // else {
      //   if(!id_no && !value) {
      //     callback(new Error('請輸入正確的身份证号码'))
      //   }
      // }
      callback()
    }
    const checkName = (rule, value, callback) => {
      const index = rule.field.split('.')[1]
      const { last_name,first_name } = this.formData.driver[index]
      if(!last_name&&!first_name) {
        callback(new Error('Fill in at least one of the content 最少填寫一項內容'))
      }
      callback()
    }
    const checkBirth = (rule, value, callback) => {
      const { birth } = this.formData.driver[0]
      if(!birth)
      {
        callback(new Error('Please enter 請輸入'))
      }
      let isAfter = this.check18Years(birth,new Date())
      if(!isAfter){
        callback(new Error('Must be 18 years of age or above\n' +
          '必須年滿18歲'))
      }
      callback()
    }
    const checkPPNo = (rule, value, callback) => {
      const index = rule.field.split('.')[1]
      const { pp_no,id_type } = this.formData.driver[index]||{}
      const { policy_holder_id_type } = this.formData
      if(policy_holder_id_type===2&& !pp_no && !value) {
        callback(new Error('請輸入Passport'))
      }
      callback()
    }
    const checkSelect = (rule, value, callback) => {
      const index = rule.field.split('.')[1]
      const { pp_no,id_type } = this.formData.driver[index]||{}
      const { policy_holder_id_type } = this.formData
      if(value<=0) {
        callback(new Error(this.$t('placeholder.select')))
      }
      callback()
    }

    return {
      oldFormDataLock:-1,
      isLastName:true,
      isFirstName:true,
      id_type: '',
      driver: {
        first_name:'',
        last_name:'',
        title:'',
        name: '',
        occ_id: '',//i
        occ: '',//i
        birth: '',
        driver_experience_id: '',//1:less than 1 years /2: 1-2  years /3: 2-5 years/4: more than 5 years
        id_type: 1,//1-hkid 2-passport,
        id_no: '',
        pp_no: '',
        relationship_id: '',//1 Parent /2 Child /3 Spouse / 4 Relative /5 Friend /6 Employee /7 Employer
        has_accident:'',
        accidents:'',
        is_deducted_point:'',
        deducted_points:'',
        deducted_point_reason_id:[],
        accident_reason_id:[],
        deducted_point_reason:'',
        driving_licence_id:'',

        accident_reason:'',// 意外事故原因

        accidents_file_id:['','',''],// 口供紙
        accidents_file:[{},{},{}],// 口供紙
        accidents_file_url:['','',''],
        id_file_id:'',// 证件文件 HKID/PASSPORT文件
        id_file:{},// 证件文件 HKID/PASSPORT文件
        id_file_url:'',// 证件文件 HKID/PASSPORT文件

        is_declined:'',// 是否被保險公司拒絕或取消保險 1-yes 2-no
        declined_reason:'',// 被保險公司拒絕或取消保險原因
        is_no_driving_ability:'',// 是否被健康或精神上影響駕駛能力 1-yes 2-no
        no_driving_ability_reason:'',// 被健康或精神上影響駕駛能力原因
        is_dangerous_driving:'',// 是否有危險駕駛記錄 1-yes 2-no
        dangerous_driving_reason:'',// 有危險駕駛記錄原因

        dangerous_driving_file_id:'',// 法庭信
        dangerous_driving_file:{},// 法庭信
        dangerous_driving_file_url:'',// 法庭信

        driving_licence_file_id:'',
        driving_licence_file:{},
        driving_licence_file_url:'',

        mec_file_id:'',
        mec_file:{},
        mec_file_url:'',
      },
      formData: {
        policy_holder_is_driver:'',
        driver: [{
          ...this.driver
        }]
      },
      rules: {
        id_no: [
          { validator: checkIdNo, trigger: ['blur'] }
        ],
        pp_no: [
          { validator: checkPPNo, trigger: ['blur'] }
        ],
        birth: [
          { validator: checkBirth, trigger: ['change', 'blur'] }
        ],
        questionCheck: [
          { validator: checkSelect, trigger: ['change', 'blur'] }
        ],
        first_name: [
          { validator: checkName, trigger: ['blur','change'] }
        ],
        last_name: [
          { validator: checkName, trigger: ['blur','change'] }
        ],
      },
    }
  },
  computed:{
    ...mapState('quotation', ['productID']),
    first_driver_name(){
      let {driver} = this.formData||{}
      if(driver.length>0)
      {
        let {last_name,first_name} = driver[0]
        return last_name||first_name
      }
      return true
    },
    last_driver_name(){
      let {driver} = this.formData||{}
      if(driver.length==2)
      {
        let {last_name,first_name} = driver[1]
        return last_name||first_name
      }
      return true
    },
    dirverExpList(){
      return (id)=>{
        let {driving_experience} = this.globalOptions||{}
        let arr = driving_experience.filter(item=>item.class_id==id)
        return arr.length>0?arr[0].item||[]:[]
      }
    },
    showMotor(){
      return this.productID==10
    },
    isMotorcycle(){
      return this.form.class_id==1003
    },
    titles(){
      return this.productID==10?'Driver Details':'Insured Detail'
    },
    relationshipTypeList(){
      let arr = this.relationshipOptions
      arr = arr.filter(item=>{
        if(this.isCompany)
          return item.id==6||item.id==7
        else{
          return ![6,7].includes(item.id)
        }
      })
      return arr
    },
    relationshipList(){
      let arr = this.relationshipOptions
      arr = arr.filter(item=>{
        if(this.isCompany)
          return item.id==6||item.id==7
        else{
          return ![6,7,8].includes(item.id)
        }
      })
      return arr
    },
    isCompany(){
      return this.clientType==2
    },
    isDisable(){
      return this.clientType==1&&this.formData.policy_holder_is_driver==1
    }
  },
  watch:{
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        if(this.formDataLock!==this.oldFormDataLock){
          this.oldFormDataLock = this.formDataLock
          let {drivers=[{...this.driver}],policy_holder_is_driver} =val||{}
          if(drivers.length>0){
            drivers.filter(item=>{
              if(item.id_type==2){
                item.pp_no = item.id_no
              }
              let {accidents_file=[],accidents_file_id=[],deducted_point_reason_id,accident_reason_id} = item||{}
              let len = accidents_file&&accidents_file.length||0
              let len_id = accidents_file_id&&accidents_file_id.length||0
              let new_file = accidents_file||[]
              let new_file_id = accidents_file_id||[]
              if(len<3){
                for(var i=len;i<3;i++)
                {
                  new_file.push({url:''})

                }
              }
              if(len_id<3){
                for(var i=len_id;i<3;i++)
                {
                  new_file_id.push('')
                }
              }
              item.accidents_file = new_file
              item.accidents_file_id = new_file_id
              item.deducted_point_reason_id = deducted_point_reason_id||[]
              item.accident_reason_id = accident_reason_id||[]
            })
            this.formData.driver = drivers
          }
          this.driver  = JSON.stringify(this.driver)
          this.formData.policy_holder_is_driver = policy_holder_is_driver
          this.handlerPolicyDriverInfo()
          this.$emit('update:driverDocument',this.formData)
        }
      }
    },
    policyOneDriver: {
      immediate: true,
      handler(status) {
      }
    },
    first_driver_name:{
      immediate:true,
      handler(val){
        this.$nextTick(()=>{
          if(this.$refs.form){
            if(!val&&!this.isFirstName){
              this.$refs.form.validateField(['driver.0.last_name','driver.0.first_name'])

            }
            else  this.$refs.form.clearValidate(['driver.0.last_name','driver.0.first_name'])
            this.isFirstName = false
          }
        })
      }
    },
    last_driver_name:{
      immediate:true,
      handler(val){
        this.$nextTick(()=>{
          if(this.$refs.form){
            if(!val&&!this.isLastName){
              this.$refs.form.validateField(['driver.1.last_name','driver.1.first_name'])

            }
            else  this.$refs.form.clearValidate(['driver.1.last_name','driver.1.first_name'])
            this.isLastName = false
          }
        })
      }
    }
  },
  methods: {
    handlerChange(){
      console.log('this---currentTarget',event.currentTarget)
      console.log('this---target',event.target)
    },
    submitForm(formName) {
      this.$refs['form'].validate((valid,rules) => {
        if(valid) {
          let params = {}
          let {driver} = this.formData
          driver.filter(item=>{
            item.id_no=item.id_type==2?item.pp_no:item.id_no
          })
          this.$emit('getFormData', formName, this.formData)
        } else {
          this.scrollToValidDom(rules)
        }
      })
    },
    //添加司机
    handlerAddDriver() {
      let driver = JSON.parse(this.driver)
      if( this.formData.driver.length>=2)
        return
      this.formData.driver.push(driver)
      this.$emit('update:driverDocument',this.formData)
    },
    handlerDeleteDriver(index) {
      if (this.formData.driver.length <= 1) return
      this.formData.driver.splice(index, 1)
      this.$emit('update:driverDocument',this.formData)
    },
    // 關聯投保人資料
    handlerPolicyDriverInfo() {
      const {
        client_type,
        policy_holder_occ_id,
        policy_holder_name, policy_holder_mobile, policy_holder_email,
        policy_holder_id_no, policy_holder_id_type, policy_holder_occ,
        policy_holder_birth,policy_holder_first_name,policy_holder_last_name,policy_holder_title
      } = this.form||{}
      let {policy_holder_is_driver} = this.formData||{}
      // console.log('policy_holder_id_no',data)
      this.policy_holder_is_driver=policy_holder_is_driver
      if(policy_holder_is_driver==1&&client_type!=2)
      {
        Object.assign(this.formData.driver[0], {
          name: policy_holder_name,
          last_name:policy_holder_last_name,
          first_name:policy_holder_first_name,
          occ_id: policy_holder_occ_id,//i
          occ: policy_holder_occ,//i
          birth: policy_holder_birth,
          id_type: policy_holder_id_type,//1-hkid 2-passport,
          id_no: policy_holder_id_no,
          pp_no: policy_holder_id_no,
          relationship_id:8,
          title:policy_holder_title
        })
      }
      // this.formData.driver[0] = {
      //   ...this.formData.driver[0],
      //   ...{
      //     name: policy_holder_name,
      //     last_name:policy_holder_last_name,
      //     first_name:policy_holder_first_name,
      //     occ_id: policy_holder_occ_id,//i
      //     occ: policy_holder_occ,//i
      //     birth: policy_holder_birth,
      //     id_type: policy_holder_id_type,//1-hkid 2-passport,
      //     id_no: policy_holder_id_no,
      //     relationship_id:8,
      //     title:policy_holder_title
      //   }
      // }
    }
  }
}
</script>

<style scoped lang="scss">
.dirver-form {
  .row-bg {
    background: #fff;
    border-radius: 8px;
    padding: 10px 0;
    margin-bottom: 10px;
    margin-right: 0!important;
    margin-left: 0!important;
    .flex {
      padding: 10px;

      span {
        color: #000;
        font-size: 16px;
        font-weight: 500;

        &:nth-child(2) {
          font-size: 14px;
          color: #076081;
        }
      }
    }
  }

  .flex {
    justify-content: space-between;
    align-items: center;
  }
}
.pd-l-0{
  padding-left: 0!important;
}
.pd-r-0{
  padding-right: 0!important;
}
</style>
